import React from 'react';
import {Route, Routes} from "react-router-dom";
import WelcomePage from "./components/WelcomePage";
import Dashboard from "./features/dashboard/dashboard";
import './App.css';
import ProtectedRoute from "./app/ProtectedRoute";
import Header from "./components/Header";
import {CssVarsProvider, useColorScheme} from '@mui/joy/styles';



function App() {

  return (
      <CssVarsProvider>
          <main>
          <Header></Header>

          <Routes>
              <Route path="/welcome" element={<WelcomePage/>} />
              <Route path="/" element={
                  <ProtectedRoute><Dashboard/></ProtectedRoute>} />
              <Route path="/dashboard" element={
                  <ProtectedRoute><Dashboard/></ProtectedRoute>} />
          </Routes>
          </main>
      </CssVarsProvider>
  )
}

export default App;
