import React from "react";
import { useAuth0} from "@auth0/auth0-react";
import {Button} from "@mui/joy";
import MenuItem from "@mui/joy/MenuItem";


function AuthMenuItem() {
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    if(isAuthenticated){
        return <MenuItem onClick={() => logout({logoutParams: {returnTo: process.env.REACT_APP_AUTH0_LOGOUT_CALLBACK_URL}})}>Logout</MenuItem>
    }
    return <MenuItem onClick={() => loginWithRedirect()}>Login</MenuItem>
}

export default AuthMenuItem;
