import Badge from "@mui/joy/Badge";
import IconButton from "@mui/joy/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React from "react";

function NotificationBadge() {
    return <Badge badgeContent={2} variant="solid" color="danger">
    <IconButton variant="soft" sx={{borderRadius: 'xl'}}>
    <NotificationsIcon/>
    </IconButton>
    </Badge>;
}

export default NotificationBadge;
