import React, {Key, useEffect, useState} from 'react';

import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {
    loadTasks,
    selectLastDataLoad,
    selectState, selectTaskItems, selectWriteState,
} from './taskListSlice';
import {
    Box, Chip,
    Divider,
    List,
    Sheet, Typography,
} from "@mui/joy";
import {Assignment, Add} from "@mui/icons-material";
import TaskItemMobile from "../../atoms/TaskItemMobile";
import TaskItemDesktop from "../../atoms/TaskItemDesktop";
import {useAuth0} from "@auth0/auth0-react";
import AxiosTestCallFunctions from "../../atoms/AxiosTestCallFunctions";
import {Task} from "../../interfaces/ITask";
import TaskCreateInputForm from "../../atoms/TaskCreateInputForm";
import TaskLoadButton from "../../atoms/TaskLoadButton";
import {setToken,selectToken} from './taskListSlice'

interface TaskListProps {
    projectName: String;
}


export function TaskList(props:TaskListProps) {
    const state = useAppSelector(selectState);
    const { getAccessTokenSilently } = useAuth0();

    const dispatch = useAppDispatch();
    // const [incrementAmount, setIncrementAmount] = useState('2');
    useEffect(() => {
        getAccessTokenSilently().then(token => {
            dispatch(setToken(token))
            dispatch(loadTasks())
        })

    },[useAppSelector(selectWriteState)==='saved']);
    // const incrementValue = Number(incrementAmount) || 0;

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(minmax(240px, 1fr))',
                gap: 2,
                mt: 3,
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    borderRadius: 'sm',
                    gridColumn: '1/-1',
                    display: {xs: 'inherit', sm: 'grid'},
                    gridTemplateColumns: '1fr',
                    '& > *': {
                        p: 1,
                        '&:nth-child(n):not(:nth-last-child(-n+1))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >

                <Typography level="h3" textAlign="center">{props.projectName}</Typography>

                {useAppSelector(selectTaskItems).map((e:Task,i:Number) =>
                    <TaskItemDesktop {...e}/>
                    )}
                <Box
                    sx={{
                        display: {sx: 'grid'},
                        gridTemplateColumns: '2fr 1fr ',
                    }}>
                    <Typography level="body3">Last Load: {useAppSelector(selectLastDataLoad)}</Typography>
                    <Chip>{useAppSelector(selectState)}</Chip>
                </Box>
            </Sheet>
            <Sheet
                variant="outlined"
                sx={{
                    display: { xs: 'none', sm: 'none' },
                    borderRadius: 'sm',
                    overflow: 'auto',
                    '& > *': {
                        '&:nth-child(n):not(:nth-last-child(n+1))': {
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                        },
                    },
                }}
            >
            </Sheet>
        </Box>
    );
}

export default TaskList;