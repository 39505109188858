import {useAuth0} from "@auth0/auth0-react";
import Header from "../../components/Header";
import TaskList from "../taskList/TaskList"
import {Counter} from "../counter/Counter";
import {Assignment} from "@mui/icons-material";
import React from "react";
import {Box, Sheet} from "@mui/joy";
import TaskCreateInputForm from "../../atoms/TaskCreateInputForm";

function Dashboard() {

    const {user} = useAuth0()

    if (!user) {
        return (<h1>hello {"NONAME"}</h1>)
    }

    return (
        <Sheet
            sx={{
                mt: "30px",
                display: {sm:"grid",sx:"flex"},
                gridTemplateColumns: "[first] 20px [second] 1fr [third] 2fr 20px [last]",
                gridTemplateRows: "[first] 30px [row1End] 1fr [third] 1fr [row4] auto [lastLine]"
            }}>
            <Box sx={{
                gridColumnStart: "first",
                gridColumn: {sx:"span 4",sm:"span 2"},
                gridRowStart: "first",
                gridRowEnd: "row1End"
            }} >
                <TaskCreateInputForm/>
            </Box>
            <Box sx={{
                gridColumnStart: "second",
                gridColumnEnd: "-4",
                gridRowStart: "row1End",
            }}>
                <TaskList projectName="inbox"/>
            </Box>
        </Sheet>
    )
};

export default Dashboard;