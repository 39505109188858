import axios from 'axios'
import {useAuth0} from "@auth0/auth0-react";
import header from "../../components/Header";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {Task, TaskList, TaskPostCall, TaskPutCall} from "../../interfaces/ITask";
import exp from "constants";
import {azFunctionUrlBuilder} from "../../helper/ApiHelper";
import {AppThunk} from "../../app/store";
import {useAppDispatch} from "../../app/hooks";
import {useState} from "react";

export function fetchData(projectID = 1) {
    return new Promise<{ data: number , accessToken:string}>((resolve) => {
            axios.get(process.env.REACT_APP_FUNCTIONS_BASE_URL+"taskCreate",{
                headers: {
                    Authorization: `Bearer `
                }
            }).then(({data}) => {
            console.log(data)
        })
    }
    )
}
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//     (amount: number): AppThunk =>
//         (dispatch, getState) => {
//             const currentValue = selectCount(getState());
//             if (currentValue % 2 === 1) {
//                 dispatch(incrementByAmount(amount));
//             }
//         };

export const postTaskAsync = createAsyncThunk(
    'task/create',
    async (data: TaskPostCall) =>{
        const {taskTitle, token} = data
        const response = await axios.post(azFunctionUrlBuilder('task'),{"title": taskTitle},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data as Task

    }
)

export const updateTaskAsync = createAsyncThunk(
    'task/create',
    async (data: TaskPutCall) =>{
        const {task, token} = data
        const response = await axios.put(azFunctionUrlBuilder('task'),task,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data as Task

    }
)


export const getTasksAsync = createAsyncThunk(
    'taskList/fetchTasks',
    async (token:String) => {
        const response = await axios.get(azFunctionUrlBuilder('task'),{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        //console.log(response.data)
        return response.data as TaskList
    }
);
// export const getTasksAsync = createAsyncThunk(
//     'taskList/fetchTasks',
//     async () => {
//         const response = await axios.get('https://jsonplaceholder.typicode.com/todos');
//         //console.log(response.data)
//         return response.data as TaskList
//     }
// );
