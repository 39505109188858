import {Box, Chip, Tooltip, Typography} from "@mui/joy";
import {Assignment, CheckBoxRounded} from "@mui/icons-material";
import React from "react";
import {Task} from "../interfaces/ITask";
import {useAppDispatch} from "../app/hooks";
import {updateTask} from "../features/taskList/taskListSlice";

function TaskItemDesktop(task: Task) {

    const date = (new Date(task.created_at));
    const dateString = date.getDate() + "." + date.getUTCMonth() + 1 + "." + date.getFullYear();
    const timeString = date.getHours() + ":" + date.getMinutes()

    const dispatch = useAppDispatch();

    return (
        <Box
            sx={{
                display: {sm: 'grid'},
                gridTemplateColumns: '1fr auto 1fr',
            }}
        >
            <Tooltip title={"Last Updated: " + task.updated_at} placement={"top-end"}>
                <Chip
                    onClick={(event => dispatch(updateTask(task)))}
                    size={"sm"}
                    variant="soft"
                    sx={{
                        textAlign: "center",
                        mr: 7,
                        minWidth: "7em",
                        height: "2em",
                    }}>{task.status}</Chip>
            </Tooltip>
            <Typography
                level="body1"
                // startDecorator={<Assignment color="primary"/>}
                // sx={{alignItems: 'flex-center'}}
                textAlign="left"
            >
                {task.title}
            </Typography>
            <Typography level="body2" textAlign="right">{dateString}<br/>{timeString}</Typography>
            {/*<Typography level="body4" sx={{color: 'success.600'}}>*/}
            {/*    {task.description}*/}
            {/*</Typography>*/}
            {/*<Box>*/}
            {/*</Box>*/}
        </Box>
    );
}

export default TaskItemDesktop;