import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import {Auth0Provider} from "@auth0/auth0-react";
import reportWebVitals from './reportWebVitals';


const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
                <Router>
                    <Auth0Provider
                        domain={process.env.REACT_APP_AUTH0_DOMAIN}
                        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                        authorizationParams={{
                            redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL
                        }}
                    >
                        <App/>
                    </Auth0Provider>
                </Router>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
