import IconButton from "@mui/joy/IconButton";
import Avatar from "@mui/joy/Avatar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import AuthMenuItem from "./AuthMenuItem";


function UserMenu() {
    const {user,isAuthenticated} = useAuth0()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl)

    const handleClose = () => {
        setAnchorEl(null);
    }


       return (
           <>
           <IconButton sx={{borderRadius: 'xl'}}
               onClick={(event) => {
                   setAnchorEl(event.currentTarget);
                   }}
                       aria-controls={open ? 'user-menu':undefined}
                       aria-haspopup="true"
           >
               {!isAuthenticated &&(<Avatar src="/static/images/avatar/1.jpg" size="sm"/>)}
               {isAuthenticated &&(<Avatar src={user?.picture} size="sm"/>)}
               <KeyboardArrowDownIcon/>
           </IconButton>
               <Menu id="user-menu"
                     anchorEl={anchorEl}
                     open={open}
                     onClose={handleClose}
               >
                   <MenuItem onClick={handleClose} disabled={true}>{user?.nickname}</MenuItem>
                   <AuthMenuItem/>
                   <MenuItem onClick={handleClose}>something else</MenuItem>
               </Menu>
           </>
       );

}

export default UserMenu;