import {createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import {getTasksAsync, postTaskAsync, updateTaskAsync} from "./taskListAPI";
import {Task, TaskList} from "../../interfaces/ITask";

export interface TaskListState {

    projectID: number;
    lastDataLoad: string;
    apiState: 'idle' | 'loading' | 'failed' | 'loaded';
    writeTaskState: 'idle' | 'saving' | 'failed' | 'saved';
    taskList: any;
    newTaskTitle: String;
    token: String;
}

const initialState: TaskListState = {
    apiState: 'idle',
    writeTaskState: 'idle',
    lastDataLoad: 'NEVER',
    projectID: 0,
    taskList: [],
    newTaskTitle: 'new Task',
    token: ''
};


export const taskListSlice = createSlice({
    name: 'TaskList',
    initialState,
    reducers: {
        setProjectID: (state,action) => {
            state.projectID = action.payload;
        },
        getTask: (state,action) => {
            state.taskList = [action.payload]
        },
        setNewTaskTitle: (state,action:PayloadAction<String>) => {
            state.newTaskTitle = action.payload
        },
        setToken: (state,action:PayloadAction<String>) => {
            state.token = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTasksAsync.pending, (state) => {
                state.apiState = 'loading';
            })
            .addCase(getTasksAsync.fulfilled, (state,action) => {
                state.apiState = 'idle';
                state.lastDataLoad = Date().toLocaleLowerCase();
                state.taskList = action.payload as TaskList

            })
            .addCase(getTasksAsync.rejected, (state) => {
                state.apiState = 'failed';
            })
            .addCase(postTaskAsync.pending, (state) => {
                state.writeTaskState = 'saving';
            })
            .addCase(postTaskAsync.rejected, (state) => {
                state.writeTaskState = 'failed';
            })
            .addCase(postTaskAsync.fulfilled, (state) => {
                state.writeTaskState = 'saved';
            })
    },
});

export const {setProjectID, setToken, setNewTaskTitle} = taskListSlice.actions

export const selectState = (state: RootState) => state.taskList.apiState;
export const selectLastDataLoad = (state: RootState) => state.taskList.lastDataLoad;
export const selectTaskItems = (state: RootState) => state.taskList.taskList;
export const selectNewTaskTitle = (state: RootState) => state.taskList.newTaskTitle;
export const selectToken = (state: RootState) => state.taskList.token;
export const selectWriteState = (state: RootState) => state.taskList.writeTaskState;

export const updateTask =
    (task:Task): AppThunk =>
        async (dispatch, getState) => {
            const token = selectToken(getState());
            dispatch(updateTaskAsync({token: token,task:task}))
        };

export const saveNewTask =
    (): AppThunk =>
        async (dispatch, getState) => {
            const currentTitle = selectNewTaskTitle(getState());
            const token = selectToken(getState());
            dispatch(postTaskAsync({token: token,taskTitle:currentTitle}))
        };

export const loadTasks =
    (): AppThunk =>
        async (dispatch, getState) => {
            const token = selectToken(getState());
            dispatch(getTasksAsync(token));
        };

export default taskListSlice.reducer;
