import {
    Routes,
    Route,
    Navigate,
    NavLink,
    useNavigate
} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import LoadingOverlay from "react-loading-overlay-ts";

type ReactChildren = {
    children: JSX.Element
}

const ProtectedRoute = ({children}:ReactChildren) => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    // console.log(user)
    //

    return (
        <>
            <LoadingOverlay active={isLoading && !isAuthenticated} spinner />
            {(isAuthenticated && !isLoading) && children}
            {(!isAuthenticated && !isLoading) && <Navigate to="/welcome" replace />}
        </>
    )

    // if (!isAuthenticated) {
    //      return <Navigate to="/login" replace />;
    // }
    //
    // return children;

}

export default ProtectedRoute;