import {Sheet} from "@mui/joy";
import AxiosTestCallFunctions from "../atoms/AxiosTestCallFunctions";

function WelcomePage() {
    return <div className="LoginPage">
        <Sheet
            sx={{
                width: 300,
                mx: 'auto', // margin left & right
                my: 4, // margin top & botom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
            variant="plain"
        >
            <img src={process.env.REACT_APP_MAIN_LOGO_URL} className="App-logo" alt="logo" /><br/>
        </Sheet>

        </div>;
}

export default WelcomePage;