
export function azFunctionUrlBuilder(endpoint:string) {
    const baseAPIPath = process.env.REACT_APP_FUNCTIONS_BASE_URL+endpoint;

    if(process.env.NODE_ENV == 'production'){
        return baseAPIPath+"?code="+process.env.REACT_APP_FUNCTIONS_CODE
    } else {

        return baseAPIPath
    }
}