import {ColorPaletteProp, useColorScheme} from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Sheet from '@mui/joy/Sheet';
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import NotificationBadge from "../atoms/NotificationBadge";
import UserMenu from "../atoms/UserMenu";

function ModeToggle() {
    const { mode, setMode } = useColorScheme();
    return (
        <Button
            variant="outlined"
            onClick={() => {
                setMode(mode === 'light' ? 'dark' : 'light');
            }}
        >
            {mode === 'light' ? 'Turn dark' : 'Turn light'}
        </Button>
    );
}


function Header() {
    const {isAuthenticated} = useAuth0()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [color, setColor] = React.useState<ColorPaletteProp>('primary');
    return (
        <Sheet
            variant="solid"
            color={color}
            invertedColors
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexGrow: 1,
                p: 2,
                mx: -1,
                my: -2,
                borderRadius: {xs: 0, sm: 'xs'},
                minWidth: 'min-content',
                ...(color !== 'warning' && {
                    background: (theme) =>
                        `linear-gradient(to top, ${theme.vars.palette[color][600]}, ${theme.vars.palette[color][500]})`,
                }),
            }}
        >
            <IconButton
                variant="soft"
                size="sm"
                onClick={() => {
                    const colors: ColorPaletteProp[] = [
                        'primary',
                        'neutral',
                        'success',
                    ];
                    const nextColor = colors.indexOf(color);
                    setColor(colors[nextColor + 1] ?? colors[0]);
                }}
                sx={{borderRadius: '50%'}}
            >
                <img alt="" src={process.env.REACT_APP_BLOB_BASE_URL + "50xColor Icon.png"}/>
            </IconButton>
            <Box sx={{flex: 1, display: 'flex', gap: 1, px: 2}}>
                {/*{isAuthenticated && (<Chip*/}
                {/*    variant="outlined"*/}
                {/*    onClick={(event) => {*/}
                {/*        setAnchorEl(event.currentTarget);*/}
                {/*    }}*/}
                {/*    endDecorator={<KeyboardArrowDownIcon/>}*/}
                {/*>*/}
                {/*    Main*/}
                {/*</Chip>)}*/}
                {/*<Menu*/}
                {/*    variant="outlined"*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    open={Boolean(anchorEl)}*/}
                {/*    onClose={() => setAnchorEl(null)}*/}
                {/*    placement="bottom-start"*/}
                {/*    disablePortal*/}
                {/*    size="sm"*/}
                {/*    sx={{*/}
                {/*        '--List-decorator-size': '24px',*/}
                {/*        '--List-item-minHeight': '40px',*/}
                {/*        '--List-divider-gap': '4px',*/}
                {/*        minWidth: 200,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <MenuItem onClick={() => setAnchorEl(null)}>*/}
                {/*        <ListItemDecorator>*/}
                {/*            <BubbleChartIcon/>*/}
                {/*        </ListItemDecorator>*/}
                {/*        Products*/}
                {/*    </MenuItem>*/}
                {/*    <ListDivider/>*/}
                {/*    <MenuItem onClick={() => setAnchorEl(null)}>Pricing</MenuItem>*/}
                {/*    <MenuItem onClick={() => setAnchorEl(null)}>*/}
                {/*        Case studies{' '}*/}
                {/*        <Chip*/}
                {/*            variant="outlined"*/}
                {/*            size="sm"*/}
                {/*            sx={{*/}
                {/*                ml: 'auto',*/}
                {/*                bgcolor: (theme) =>*/}
                {/*                    `rgba(${theme.vars.palette.primary.mainChannel} / 0.1)`,*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Beta*/}
                {/*        </Chip>*/}
                {/*    </MenuItem>*/}
                {/*</Menu>*/}
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row-reverse', flexShrink: 0}}>
                <Box sx={{display: 'flex', gap: 2}}>
                    {isAuthenticated && (<Button
                        startDecorator={<AddIcon/>}
                        sx={{borderRadius: 'xl', display: {xs: 'none', md: 'inline-flex'}}}
                    >
                        New Task
                    </Button>)}
                    {/*<Input*/}
                    {/*    placeholder="Search for anything..."*/}
                    {/*    variant="soft"*/}
                    {/*    size="sm"*/}
                    {/*    endDecorator={*/}
                    {/*        <Typography*/}
                    {/*            component="span"*/}
                    {/*            variant="outlined"*/}
                    {/*            level="body3"*/}
                    {/*            sx={{ bgcolor: 'background.surface', mx: 0 }}*/}
                    {/*        >*/}
                    {/*            ⌘K*/}
                    {/*        </Typography>*/}
                    {/*    }*/}
                    {/*    sx={{*/}
                    {/*        '--Input-radius': '40px',*/}
                    {/*        '--Input-paddingInline': '12px',*/}
                    {/*        width: 160,*/}
                    {/*        display: { xs: 'none', lg: 'flex' },*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {isAuthenticated && (<NotificationBadge/>)}
                    <UserMenu />
                </Box>
            </Box>
        </Sheet>
    );
    // return (
    //     <div className="row header">
    //         <div className="col-sm-1">
    //             {/*<img src={process.env.REACT_APP_MAIN_LOGO_URL} className="logo"/>*/}
    //         </div>
    //         <div className="col-sm ">
    //             <NavLink  to="/"><a className="secondary" href="#">Home</a></NavLink>
    //             <NavLink to="/profile">Profile</NavLink>
    //         </div>
    //         <div className="col-sm-1">
    //             <AuthButton/>
    //             <ModeToggle/>
    //         </div>
    //     </div>
    // );

}

export default Header;