import {Box, Button, Input} from "@mui/joy";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {setNewTaskTitle, saveNewTask} from "../features/taskList/taskListSlice";


function TaskCreateInputForm() {

    const dispatch = useAppDispatch();


    return (
        <form
            onSubmit={(event)=> {
                event.preventDefault();
                dispatch(saveNewTask())
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '2fr auto',

                }}>
                <Input
                    placeholder="new Task"
                    variant="soft"
                    size="sm"
                    color="neutral"
                    sx={{
                        m: 0.5,
                    }}
                    onChange={(e)=> {dispatch(setNewTaskTitle(e.target.value))}}
                />
                <Button type="submit">Save</Button>
            </Box>
        </form>
    );
}

export default TaskCreateInputForm;